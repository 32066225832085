import React from 'react'
import './donation.css'

export default function Donation() {
    return (
        <div className='donation'>
            <h2>Con tu colaboracion nos ayudas a sostener este sueño</h2>
            <a href='/como-colaborar'>Quiero colaborar</a>
        </div>
    )
}