import React, {useState, useContext} from 'react'
import { PhotoGallery } from '../components/photoGallery/PhotoGallery'
import './Galeria.css'
import { GalleryContext } from '../context/galleryContext/GalleryContext'
import { VideoGallery } from '../components/videoGallery/VideoGallery'

export const GaleriaView = () => {

    const [navState, setNavState] = useState('foto')
    const  [viewer, setViewer, galleryView, setGalleryView, 
        galleryList, setGalleryList, imgSelected, setImgSelected] = useContext(GalleryContext)
    
        return(
            <div className='galeria-view'>
                <div className='galeria'>
                    <div className='q-somos-view'>
                        <div className='section-1 d-flex container q-somos-title'>
                            <div className='title col'>
                            </div>
                        </div>
                    </div>
                    <div className='title'>
                        <h1>Galeria</h1>
                    </div>
                    <div className='galeria-nav'>
                        <button className={navState === 'foto' ? 'selected' : ''} onClick={()=>{setNavState('foto')}} >Fotos</button>
                        <button className={navState === 'video' ? 'selected' : ''} onClick={()=>{setNavState('video')}}>Videos</button>
                    </div>
                    <div className='galeria-content'>
                        {
                            navState === 'foto' ?
                            <PhotoGallery galleryList={galleryList} /> :
                            navState === 'video' ?
                            <VideoGallery /> : ''
                        }
                    </div>
                </div>
            </div>
        )
}
